.timestamp,
.description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5em;

  color: #808080;
}

.timestamp {
  font-size: 90%;
  line-height: 1;
  flex-shrink: 0;
}

.description {
  font-weight: 600;
}

.header {
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
  width: 100%;
  text-align: left;
  padding-bottom: 0.5em;

  .columns {
    display: grid;
    grid-template-columns: 2fr 1.3fr;
  }

  .meta {
    display: flex;
    flex-direction: row;
    gap: 1em;

    align-items: center;
  }

  .meta .badge {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
  }

  .meta .runInfo {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
  }

  .assignedTo {
    display: flex;
    align-items: center;
    gap: 0.5em;
  }
}
