.timestamp,
.description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5em;

  color: #808080;
}

.timestamp {
  font-size: 90%;
  line-height: 1;
  flex-shrink: 0;
}

.issue {
  background-color: white;
  position: relative;
  margin-top: 1.5em;
  margin-bottom: 2em;

  width: 100%;
  max-width: calc(100% - 4em);

  border-radius: 10px;
  border: 2px solid #efeded;

  box-shadow: 1px 2px 6px -1px rgba(0, 0, 0, 0.2);

  transition: border 300ms ease-in-out;
}

.issue.focused {
  border-width: 6px;

  &:not(&.unresolved) {
    border-color: #aaa;
  }
}

.issue.unresolved {
  border-color: #511fff;
}

.issue .seeMore {
  padding-top: 1em;
  color: #666;
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 50%
  );
}

.issue .badge {
  display: flex;
  flex-direction: row;
  align-items: center;

  position: absolute;
  top: 0;
  left: -2em;
  transform: translateY(-50%);

  border: 2px solid #efeded;
  border-radius: 10px;
  overflow: hidden;
}

.issue.unresolved .badge {
  border-color: #511fff;
}

.issue .badge .status {
  text-transform: uppercase;
  font-weight: bold;
  background: #efeded;
  color: #474747;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.issue.unresolved .badge .status {
  color: white;
  background: #511fff;
}

.issue .badge .date {
  padding-left: 0.5em;
  padding-right: 0.5em;
  background: white;
}

.issue .controls {
  background-color: white;
  padding-left: 0.5em;
  padding-right: 0.5em;
  overflow: hidden;
  border-radius: 10px;

  display: flex;
  flex-direction: row;
  align-items: center;

  position: absolute;
  top: 0;
  right: 1.5em;
  transform: translateY(-52%);

  button:focus {
    outline: none;
  }
}

.issue .controls .assignee {
  color: #808080;
  background: white;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.issue .controls .assignee .name:not(.empty) {
  font-weight: bold;
}

.issue .controls .actionButtons {
  display: flex;
  background: white;
}

.issue .details {
  display: flex;
  flex-direction: row;
  padding: 2em;
}

.issue .details .summary,
.issue .details .activity {
  width: 50%;
}

.context .quote {
  border: 1px solid #e0d8ff;
  background: #f8f6ff;
  padding-top: 1.5em;
  padding-bottom: 1.5em;
  padding-left: 2em;
  padding-right: 1.25em;

  border-radius: 8px;
  text-align: left;
  margin-top: 0.75em;
  position: relative;

  &:before {
    content: "“";
    font-size: 200%;
    position: absolute;
    top: 38px;
    left: 14px;
    width: 0;
    height: 0;
    line-height: 0;
    color: #808080;
  }

  &:after {
    content: "”";
    font-size: 200%;
    width: 0;
    height: 0;
    line-height: 0;
    position: relative;
    top: 5px;
    left: 5px;
    color: #808080;
  }
}

.context .soloContext {
  border: 1px solid #e0d8ff;
  background: #f8f6ff;
  padding: 1em;
  font-size: 90%;
  border-radius: 8px;
  text-align: left;
  margin-top: 0.75em;
  position: relative;
}

.context .firstSeen {
  text-align: right;
  font-size: 95%;
  margin-top: 0.75em;
}

.commentBody {
  background-color: #fcfcfc;
  border: 1px solid #f2f2f2;
  margin-top: 0.5em;
  padding: 0.5em;
  margin-bottom: 0.25em;
  border-radius: 12px;
  border-top-left-radius: 0;

  transition: border 300ms ease-in-out, background-color 300ms ease-in-out;

  &.pending {
    border-style: dashed;
    background-color: white;
  }
}
