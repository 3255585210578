@keyframes shake {
  0% {
    transform: translateX(-10px);
  }
  16% {
    transform: translateX(9px);
  }
  33% {
    transform: translateX(-6px);
  }
  50% {
    transform: translateX(5px);
  }
  66% {
    transform: translateX(-2px);
  }
  83% {
    transform: translateX(1px);
  }
  100% {
    transform: translateX(0);
  }
}

.invalid {
  animation: shake 0.5s linear;
}
