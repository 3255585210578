:global(body.oops) {
  margin: 0;
  background-color: #511fff;
  color: white;
  display: flex;
  min-height: 100vh;
  min-width: 100vw;
  justify-content: center;
  align-items: center;
  font-family: sans-serif;

  transition: background-color 500ms linear;
}

.oops {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;
  height: 100%;
  width: 100%;
}

.logo {
  width: 225px;
}

.message {
  text-align: center;
  max-width: 500px;
}

.retry,
.retry:visited {
  color: white;
}

.retry:hover {
  text-decoration: none;
}
