.timestamp,
.description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5em;

  color: #808080;
}

.timestamp {
  font-size: 90%;
  line-height: 1;
  flex-shrink: 0;
}

.monitorsList .monitor {
  border-bottom: 1px solid #d5d5d5;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  display: flex;
  flex-direction: row;
  align-items: flex-end;

  min-height: 50px;

  .domain {
    font-weight: bold;
  }

  .body {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .confirmDelete {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }

  .meta {
    display: block;
    font-size: 80%;

    .nextRun:not(.never),
    .previousAlerted:not(.never) {
      font-weight: bold;
    }

    .divider {
      padding-left: 0.25em;
      padding-right: 0.25em;
    }
  }

  .actions {
    display: flex;
    gap: 0.25em;
  }
}
